import React from "react";

import "react-circular-progressbar/dist/styles.css";

import { NxuCardPanel } from "@nexford/nexford-ui-component-library";

import "./deadlines-card.scss";

/**
 * Display the upcoming deadlines for the user
 */
const DeadlinesCard = () => (
  <NxuCardPanel className="deadlines-card" testId="deadlines-card">
    <div className="deadlines-card__title-row">
      <h2>Upcoming Deadlines</h2>
    </div>
    <p>All your deadlines are completed</p>
  </NxuCardPanel>
);
export default DeadlinesCard;
