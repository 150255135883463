import { useQuery } from "@tanstack/react-query";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiGet } from "./api-client";
import { AvalibleCodes, SocialShareLink } from "../../types/social-share";

const fetchProcutCodes = async (msalInstance: IPublicClientApplication): Promise<AvalibleCodes> =>
  apiGet(`/api/socialshare/linkedin/products/finishing-courses`, "", msalInstance);

export const useProductCodes = (msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["products-codes"],
    queryFn: () => fetchProcutCodes(msalInstance),
    throwOnError: false,
    enabled: true,
    retry: 3,
    staleTime: 600000,
  });

const fetchSocialShareLink = async (productCode: string): Promise<SocialShareLink> =>
  apiGet(`/api/socialshare/linkedin/${productCode}/finishing-courses/share-link`, "");

export const useLinkedInSocialShareLink = (productCode: string) =>
  useQuery({
    queryKey: ["social-share-link"],
    queryFn: () => fetchSocialShareLink(productCode),
    throwOnError: false,
    enabled: true,
    retry: 3,
    staleTime: 600000,
  });
