import React from "react";
import { IonIcon } from "@ionic/react";
import { giftOutline } from "ionicons/icons";

import { useMsal } from "@azure/msal-react";

import { useAuthContext } from "utils/context/Auth";
import { useLearner } from "utils/hooks/learners";
import { isTestEnv } from "constants/feature-flags";

import { NxuContentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import Greeting from "components/atom/greeting";
import ProgramSummaryCard from "components/molecule/program-summary-card";
import DeadlinesCard from "components/molecule/deadlines-card";

import { ReactComponent as EarlyAdopterWelcome } from "assets/img/early-adopter-welcome.svg";

import "./dashboard.scss";

/**
 * MyNXU App landing page
 */
const Dashboard = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();
  const { data: aggregateLearnerProfile } = useLearner(!!userAccount, instance);

  const profileName = aggregateLearnerProfile
    ? `${aggregateLearnerProfile.firstName} ${aggregateLearnerProfile.lastName}`
    : "";
  const displayProgramSummary = aggregateLearnerProfile?.provider === "Nexford";

  if (aggregateLearnerProfile) {
    return (
      <PageContent>
        <section className="dashboard">
          <h1>
            <Greeting />, <span data-hj-suppress>{profileName}</span>!
          </h1>

          {displayProgramSummary && <ProgramSummaryCard />}

          {!displayProgramSummary && (
            <>
              <div className="dashboard__early-adopter-icon">
                <EarlyAdopterWelcome aria-hidden="true" />
              </div>
              <CardPanel className="dashboard__early-adopter-welcome" centre={true}>
                <p>
                  Explore the new platform for Nexford University learners and its first two features, Program and
                  Wallet. In Program you can see all of your past, current and future courses and Wallet makes paying
                  tuition easier than ever!
                </p>
                <p>
                  Please explore and let us know your thoughts via the feedback widget or by contacting our success
                  team.
                </p>
              </CardPanel>
            </>
          )}

          {isTestEnv && <DeadlinesCard />}

          <NxuPrimaryButton
            className="dashboard-page__referral"
            size="default"
            expand="full"
            href="https://hub.nexford.edu/refer-program"
            target="_blank"
          >
            REFER A FRIEND TO NEXFORD
            <IonIcon slot="end" icon={giftOutline} />
          </NxuPrimaryButton>

          <GetInTouch type="feedback">
            <p>Talk to a Success Advisor</p>
          </GetInTouch>
        </section>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <NxuContentLoading />
    </PageContent>
  );
};

export default Dashboard;
